import {SocialIcon } from 'react-social-icons';

import Logo from './Logo.js';

function Intro(){
  let socialHeight = '4em';
  let socialWidth = '4em';
    return (
        <div>
            <Logo/>
            <img src="pic.png" id = "pic" alt=''></img>
            <div id="roles">
              <b>Data Engineer <br/>
                        & <br/>
                 Data Scientist</b>
            </div>
            <div id="socials">
            <SocialIcon id="social_hover" url="https://www.linkedin.com/in/jack-ribarich/" style={{ height: socialHeight, width: socialWidth }}/>
            <SocialIcon id="social_hover" url="https://github.com/jribarich" bgColor='white' style={{ height: socialHeight, width: socialWidth }}/>
            <SocialIcon id="social_hover" url="http://ribari.ch" style={{ height: socialHeight, width: socialWidth }} bgColor="#037d50"/>
            </div>
        </div>
    )
}

export default Intro;