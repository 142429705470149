function Logo() {
    return (
      <div style={{fontSize: "50px"}}>
        <b class="animated_background" id="shift_J">J</b>
        <b class="logo" id="white_logo">ack</b>
        <b class="animated_background" id="shift_R">R</b>
        <b class="logo" id="white_logo">ibarich</b>
      </div>
    );
  }

  export default Logo;